import React, { useState } from 'react';
import { useMedia } from 'react-use';
import PcHead from './pc/pc_head';
import PhoneHead from './phone/phone_head';
import { useViewport } from '../../../../unitl/screen.js';
function Head() {
    const { width } = useViewport();
    const breakpoint = 640;
    const isWide = useMedia('(min-width: 480px)');
    if (width > breakpoint) {
        return (
            <PcHead />
        )
    } else {
        return (
            <PhoneHead />
        )
    }

}
export default Head;
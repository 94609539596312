import React, { useState } from "react";
import "./index.css";
import { useViewport } from "../../../../unitl/screen";

function Foot() {
  const { width } = useViewport();
  const breakpoint = 620;
  const iswide = width < breakpoint;

  const goAgree = () => {
    window.location.href = "http://www.myxdb.com/share/agreement.html";
  };
  const goPrivacy = () => {
    window.location.href = "http://www.myxdb.com/share/privacy.html";
  };
  const goIcp = () => {
    window.location.href = "https://beian.miit.gov.cn/";
  };
  return (
    <div>
      <ul
        className="footlist"
        style={{
          marginTop: "40px",
          width: "80%",
          fontSize: iswide ? "8px" : "16px",
        }}
      >
        <li>
          <a className="foottext">关于我们</a>
        </li>
        <li>
          <a className="foottext">联系方式</a>
        </li>
        <li>
          <a className="foottext" onClick={() => goAgree()}>
            用户协议
          </a>
        </li>
        <li>
          <a className="foottext" onClick={() => goPrivacy()}>
            隐私政策
          </a>
        </li>
        <li>
          <a className="foottext">社区规范</a>
        </li>
      </ul>
      <div className="Record">
        {/* <p>Copyright © 2020-2021 獾友行动榜</p> */}
        <a href="https://beian.miit.gov.cn/" target="_blank" className="foottext">
          {'浙ICP备18049902号-1'}       杭州獾行动网络科技有限公司版权所有
        </a>
      </div>
    </div>
  );
}

export default Foot;

import React, { useState, useEffect, useRef } from "react";
import "./index.css";
import PICKME from "../../img/Lottery/PICKME.png";
import Portrait from "../../img/Lottery/Portrait.png";
import CraneMachine from "../../img/Lottery/CraneMachine.png";
import StartButtonHover from "../../img/Lottery/StartButtonHover.png";
import StartButtonClick from "../../img/Lottery/StartButtonClick.png";
import LittleCuteClick from "../../img/Lottery/LittleCuteClick.png";
import LittleCuteHover from "../../img/Lottery/LittleCuteHover.png";
import { Get } from "../../Server/request";
import lottie from "lottie-web";
import animationJsonData from "../../lottie/lf20_5izsbkkx.json";

function Lottery() {
  const [Num, SetNum] = useState(3);

  const [ModalShow, setModalShow] = useState(false);

  const [Isstart, SetStart] = useState(false);

  const [selectClassName, SetSelectName] = useState(false);

  const [name, setName] = useState("幸运小可爱");

  const [Being, SetBeing] = useState(false);
  const didMountRef = useRef(false);

  const [textList, setList] = useState([
    { name: "胜男老师的小可爱1", select: true },
    { name: "胜男老师的小可爱2", select: false },
    { name: "胜男老师的小可爱3", select: false },
    { name: "胜男老师的小可爱4", select: false },
    { name: "胜男老师的小可爱5", select: false },
    { name: "胜男老师的小可爱6", select: false },
  ]);
  const StartChose = () => {
    SetBeing(true);
    SetNum(2);
  };

  useEffect(() => {
    let timer;
    console.log(didMountRef, "didMountRefdidMountRef");
    if (didMountRef.current) {
      if (Num > 0) {
        timer = setTimeout(() => {
          SetNum(Num - 1);
        }, 1000);
      } else {
        // SetNum(3);
        timer = null;
        // props.history.push("/order");
      }

      return () => {
        clearInterval(timer);
      };
    } else {
      didMountRef.current = true;
    }
  }, [Num]); //

  const ChoseClass = () => {};

  return (
    <div className="BackColor">
      {ModalShow && (
        <div className="Modal">
          <div className="choseclass">
            <p className="textcenter" style={{ color: "#79799B" }}>
              选择班级
            </p>
          </div>
          <div className="classList">
            {textList.map((ele, i) => {
              return (
                <div
                  style={{
                    width: "616px",
                    height: "92px",
                    backgroundColor: ele.select ? "#E6939D" : "",
                    borderRadius: "46px",
                    marginTop: "9px",
                    marginBottom: "9px",
                    borderWidth: ele.select ? "4px" : "0px",
                    borderColor: "#ffffff",
                    borderStyle: "solid",
                  }}
                  key={i}
                >
                  <p className="choseText">{ele.name}</p>
                </div>
              );
            })}
            <div className="confirm" onClick={() => this.Confirm()}>
              <p className="textcenter" style={{ color: "#79799B" }}>
                确定
              </p>
            </div>
          </div>
        </div>
      )}
      <div className="LeftImg">
        <img src={PICKME} className="pickme"></img>
        <img src={Portrait} className="Portrait"></img>
      </div>
      <div>
        <div className="CraneMachine">
          {Being ? (
            <div
              style={{
                textAlign: "center",
                width: "320px",
                margin: "0px auto",
                height: "380px",
              }}
            ></div>
          ) : (
            <p className="LotteryNameResult" style={{ color: "#79799B" }}>
              {name}
            </p>
          )}
          <div style={{ display: "flex", flexDirection: "row" }}>
            <a>
              <div
                className="StartButtonClick"
                onClick={() => !Being && StartChose()}
              >
                222222222222
              </div>
            </a>
            <a>
              <div
                className="LittleCuteClick"
                onClick={() => !Being && ChoseClass()}
              >
                333333333333
              </div>
            </a>
          </div>
          <p
            className="LotteryResult"
            style={{ marginRight: "10px", color: "#79799B" }}
          >
            {Num}
          </p>
        </div>
      </div>
    </div>
  );
}

export default Lottery;

import React, { useState } from 'react';
import './phone_score.css';
// import '../Common/index.css';
import phone_Universal from '../../../../../img/phone/phone_Universal.png';
import phone_Profession from '../../../../../img/phone/phone_Profession.png';
import PhoneIntrduction from '../../intrduction/Phone';
function PhoneScore() {
    return (
        <div className='phone_Scorebody'>
            <div className='score_content'>
                <img src={phone_Universal} style={{ width: '95%', height: '183px' }} />
                <img src={phone_Profession} style={{ width: '95%', height: '183px' }} />
                <PhoneIntrduction
                    title={'通用记分器&专业记分器'}
                    detail={['记录对手、搭档、场数、时间、得分、失误、统计、分 析、预测、胜负、级别、排行、语音...一切尽在掌握！', ' 针对不同场景、不同需求的人群定制的专业羽毛球记分器。', ' 通用记分器遵循简单易用的原则，让您随时随地开始比赛', ' 专业记分器严格遵守职业羽毛球规则，让您展现实力的同时尽显专业风范！']}
                    supplement={'纪录历史  分析趋势  重温往事'}
                    btntext={'开始比赛'}
                />
            </div>
            <div className='phone_scoreblackMask'>
            </div>
        </div>
    );
}
export default PhoneScore;
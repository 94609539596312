import React, { useState } from 'react';
import { useMedia } from 'react-use';
import Android from '../../../../../img/phone/Android.png';
import iOS from '../../../../../img/phone/iOS.png';
import logo_1 from '../../../../../img/phone/logo_1.png';
import logo_2 from '../../../../../img/phone/logo_2.png';
import menu from '../../../../../img/phone/menu.png';
import wechat from '../../../../../img/phone/wechat.png';
import './phone_head.css'
import downloadApp from '../../../../../unitl/download';
import scrollToAnchor from '../../../../../unitl/href';
import { Popover, Button } from 'antd';
import 'antd/dist/antd.css';
function PhoneHead() {
    const content = (
        <div style={{ backgroundColor: '#000' }}>
            <p>1</p>
            <img src={wechat} style={{ width: '100px', height: '100px' }} />
        </div>
    )
    return (
        <div className='phone_body'>
            <div className='phone_black'>
                <div className='phone_logomenu'>
                    <img src={logo_1} className='phone_logo1' />
                    <div className='phone_menu'>
                        <img src={menu} className='menuImg' />
                        <ul className='menuhiden'>
                            <li><a className='text'>首页</a></li>
                            <li><a className='text' onClick={() => scrollToAnchor('Introduction')}>功能介绍</a></li>
                            <li><a className='text' onClick={() => scrollToAnchor('link')}>联系我们</a></li>
                            <li>
                                <Popover content={content} >
                                    <img src={wechat} style={{ width: '25px', height: '30px' }} />
                                </Popover>
                            </li>
                        </ul>
                    </div>
                </div>
                <img src={logo_2} className='phone_logo2' />
                <div style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center', marginBottom: '42px' }}>
                    <img src={Android} className='phone_download' style={{ marginBottom: '12px' }} onClick={() => downloadApp()} />
                    <img src={iOS} className='phone_download' />
                </div>
            </div>
        </div>
    );
}

export default PhoneHead;
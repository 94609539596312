import React, { useState } from 'react';
import './phone_Team.css';
// import '../Common/index.css';
import phone_teamAge from '../../../../../img/phone/phone_teamAge.png';
import phone_teamTarget from '../../../../../img/phone/phone_teamTarget.png';
import PhoneIntrduction from '../../intrduction/Phone';
function PhoneTeam() {
    return (
        <div className='phone_Teambody'>
            <div className='Team_content'>
                <img src={phone_teamAge} style={{ width: '72%', height: '245px' }}  alt='年龄分布'/>
                <img src={phone_teamTarget} style={{ width: '72%', height: '245px' }} />
                <PhoneIntrduction
                    title={'球队管理 —— 创建&加入球队'}
                    detail={['谁都能轻松的成为球队管理者。', ' 球队成员统筹管理、球队指标实时监控、球队活动一键创建、活动经费自动记录、图片视频记录美好时光、物料管理清晰透明。 手把手教你成为一名称职的羽毛球队长！']}
                    supplement={'纪录历史  分析趋势  重温往事'}
                    btntext={'创建球队'}
                />
            </div>
            <div className='phone_TeamblackMask'>
            </div>
        </div>
    );
}
export default PhoneTeam;
import React, { useState, useEffect, useRef } from "react";
import Head from "../home/Component/Header";
import "./index.css";
import logo from "../../img/pc/logo.png";
import logo_1 from "../../img/pc/logo_1.png";
import logo_2 from "../../img/pc/logo_2.png";
import urlObj from "./url.json";
import "./pc_head.css";
import { Link } from "react-router-dom";
function Video() {
  const [url, Seturl] = useState("");
  useEffect(() => {
    Seturl(urlObj[1]);
    console.log(urlObj[1], "url");
  }, []);
  return (
    <div>
      <Link to="/">
        <div className="_Head">
          <div>
            <a className="text">
              <p style={{ marginRight: "20px", color: "white" }}>首页</p>
            </a>
          </div>

          <div className="me">
            <img src={logo_1} className="logo1" />
          </div>
        </div>
      </Link>

      {url && (
        <video controls className="videoStyle">
          <source src={url} type="video/mp4" />
        </video>
      )}
    </div>
  );
}

export default Video;


import React, { useState } from 'react';
import './index.css'
import downloadApp from '../../../../../unitl/download';
function PhoneIntrduction(props) {
    return (
        <div className='phone_Introduction'>
            <p className='phone_intrduction_title'>{props.title}</p>
            <div style={{ marginTop: '17px', marginBottom: '19px' }}>
                {
                    props.detail && props.detail.map((ele, i) => {
                        return (
                            <p key={i} className='pchone_intrduction_detail'>{ele}</p>
                        )
                    })
                }
            </div>
            <p className='phone_intrduction_supplement'>{props.supplement}</p>
            <div className='phone_btn' onClick={() => downloadApp()}>
                <p style={{ color: '#fff' }}>{props.btntext}</p>
            </div>
        </div>
    )
}
export default PhoneIntrduction;
import React, { useState } from "react";
import "./pc_form.css";
import SubmitForm from "../ant_form";
import { useViewport } from "../../../../../unitl/screen.js";
function PcForm() {
  return (
    <div style={{ display: "flex", alignItems: "center", overflow: "hidden" }}>
      <div className="pc_FormContent">
        <SubmitForm />
      </div>
      <div className="pc_formbody">
        <div className="pc_FormblackMask"></div>
      </div>
    </div>
  );
}

export default PcForm;

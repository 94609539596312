import React, { useState } from "react";
import { useMedia } from "react-use";
import logo from "../../../../../img/pc/logo.png";
import logo_1 from "../../../../../img/pc/logo_1.png";
import logo_2 from "../../../../../img/pc/logo_2.png";
import wechat from "../../../../../img/pc/wechat.png";
import iOS from "../../../../../img/pc/iOS.png";
import Android from "../../../../../img/pc/Android.png";
import qrcode from "../../../../../img/pc/qrcode.png";
import "./pc_head.css";
import downloadApp from "../../../../../unitl/download";
import scrollToAnchor from "../../../../../unitl/href";
import { Popover, Button } from "antd";
import "antd/dist/antd.css";
function PcHead() {
  const content = (
    <div style={{ backgroundColor: "#000" }}>
      <p>1</p>
      <img src={wechat} style={{ width: "100px", height: "100px" }} />
    </div>
  );
  return (
    <div className="body">
      <div className="black_top">
        <div className="logoMenu">
          <div className="me">
            <img src={logo} className="logo" />
            <img src={logo_1} className="logo1" />
          </div>
          <ul className="menu">
            <li className="menuitem">
              <a className="text">首页</a>
            </li>
            <li className="menuitem">
              <a
                className="text"
                onClick={() => scrollToAnchor("Introduction")}
              >
                功能介绍
              </a>
            </li>
            <li className="menuitem">
              <a className="text" onClick={() => scrollToAnchor("link")}>
                联系我们
              </a>
            </li>
            <li className="menuitem">
              <Popover content={content}>
                <img src={wechat} className="wechat" />
              </Popover>
            </li>
          </ul>
        </div>
        <img src={logo_2} className="logo2" />
        <div className="tip">
          <div className="slogan">
            <p className="text" style={{ fontSize: "16px" }}>
              行动榜 · 每一分都有故事
            </p>
            <p className="text" style={{ marginTop: "20px", fontSize: "16px" }}>
              为羽毛球爱好者准备的记分神器
            </p>
          </div>
          <ul className="menu">
            <li className="downloaditem">
              <img src={iOS} className="download" />
            </li>
            <li className="downloaditem">
              <img
                src={Android}
                className="download"
                onClick={() => downloadApp()}
              />
            </li>
            <li className="downloaditem">
              <img src={qrcode} className="qrcode" />
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default PcHead;

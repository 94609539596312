import React, { useState } from 'react';
import { useMedia } from 'react-use';
import PcScore from './pc/pc_score';
import PhoneScore from './phone/phone_score.js';
import { useViewport } from '../../../../unitl/screen.js';


function Score() {
    const { width } = useViewport();
    const breakpoint = 620;
    const isWide = useMedia('(min-width: 480px)');
    if (width > breakpoint) {
        return (
            <PcScore />
        )
    } else {
        return (
            <PhoneScore />
        )
    }

}

export default Score;
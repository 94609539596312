import React, { useState } from 'react';
import './pc_Personal.css';
// import '../Common/index.css';
import pc_Personalrank from '../../../../../img/pc/pc_Personalrank.png';
import pc_Personalechart from '../../../../../img/pc/pc_Personalechart.png';
import PcIntrduction from '../../intrduction/Pc';
function PcPersonal() {
    return (
        <div style={{ display: 'flex', alignItems: 'center', overflow: 'hidden' }}>
            <div className='pc_PersonalContent'>

                <PcIntrduction
                    title={'数据排行 —— 个人信息记录、排行榜'}
                    detail={['你的运动数据、对阵历史、比赛纪录、好友球友统统一目了然。 让您的运动生涯有一个清晰的纪录，每一天的积累都是未来的成就与财富', ' 全国排行、好友排行，让您对个人定位有清晰的认知，理性运动，有目标的提升自己']}
                    btntext={'创建球队'}
                    supplement={'纪录你的运动生涯'}
                />
                <div style={{ flexDirection: 'column', display: 'flex', alignItems: 'flex-end' }}>
                    <img src={pc_Personalrank} style={{ width: '374px', height: '364px' }} />
                    <img src={pc_Personalechart} style={{ width: '374px', height: '364px' }} />
                </div>
            </div>
            <div className='pc_Personalbody'>
                <div className='pc_PersonalblackMask'>
                </div>
            </div>
        </div>

    );
}

export default PcPersonal;
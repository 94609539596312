import "core-js/stable";
import "regenerator-runtime/runtime";
import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Switch,
  Redirect,
} from "react-router-dom";
import logo from "./logo.svg";
import "./App.css";
import Home from "./Page/home";
import Lottery from "./Page/lottery/1";
import Video from "./Page/Video/index";
function App() {
  return (
    <Router>
      <Switch>
        <Route path="/" component={Home} exact />
        <Route path="/Lottery" component={Lottery} />
        <Route path="/Video" component={Video} />
        {/* <Route component={ErrorPage} /> */}
      </Switch>
    </Router>
  );
}

export default App;

import React, { useState } from 'react';
import { useMedia } from 'react-use';
import PcMatch from './pc/pc_match.js';
import PhoneMatch from './phone/phone_match.js';
import { useViewport } from '../../../../unitl/screen.js';

function Match() {
    const { width } = useViewport();
    const breakpoint = 620;
    if (width < breakpoint) {
        return (
            <PhoneMatch />
        )
    } else {
        return (
            <PcMatch />
        )
    }
}
export default Match;
import React, { useState } from 'react';
import { useMedia } from 'react-use';
import PcPersonal from './pc/pc_Personal.js';
import PhonePersonal from './phone/phone_Personal.js';
import { useViewport } from '../../../../unitl/screen.js';

function Personal() {
    const isWide = useMedia('(min-width: 480px)');
    const { width } = useViewport();
    const breakpoint = 620;
    if (width > breakpoint) {
        return (
            <PcPersonal />
        )
    } else {
        return (
            <PhonePersonal />
        )
    }
}
export default Personal;
import React, { useState } from "react";
import "./phone_match.css";
// import '../Common/index.css';
import Phone_crateMacth from "../../../../../img/phone/Phone_crateMacth.png";
import Phone_matchRecord from "../../../../../img/phone/Phone_matchRecord.png";
import Phone_machDetail from "../../../../../img/phone/Phone_machDetail.png";
import PhoneIntrduction from "../../intrduction/Phone";
function PhoneScore() {
  return (
    <div className="phone_Matchbody">
      <div className="Macth_content">
        <img
          src={Phone_crateMacth}
          style={{ width: "100%", height: "200px" }}
        />
        <img
          src={Phone_matchRecord}
          style={{ width: "100%", height: "200px" }}
        />
        <img
          src={Phone_machDetail}
          style={{ width: "100%", height: "200px" }}
        />
        <PhoneIntrduction
          title={"赛事系统"}
          detail={[
            "小到个人、俱乐部，大到全国乃至全球赛事，轻松完成编排、发布、过程、统计等赛事全过程",
            " 针对不同场景、不同需求的人群定制的专业羽毛球记分器。",
            "让组织赛事不再是一个劳神伤财的过程，让组织者和参与者都享受到一站式服务。",
            " 赛事一键生成，选手一键报名，赛程自动生成，实时追踪永久保留。",
          ]}
          supplement={"一个人10分钟创建整场赛事"}
          btntext={"组织赛事"}
        />
      </div>
      <div className="phone_MatchblackMask"></div>
    </div>
  );
}
export default PhoneScore;

import React, { useState } from 'react';
import { useMedia } from 'react-use';

function Description(props) {
    const isWide = useMedia('(min-width: 480px)');
    console.log(props)
    const { style, cn, en, id } = props
    if (isWide) {
        return (
            <div style={{ flexDirection: 'column', display: 'flex', alignItems: 'center', marginBottom: '112px', marginTop: '113px' }} id={id}>
                <p style={{ color: '#3D3744', fontSize: '24px', fontFamily: 'Source Han Sans SC', fontWeight: 'bold', lineHeight: '30px', marginBottom: '18px' }}>{cn}</p>
                <p style={{ color: '#898989', fontWeight: 400, fontSize: '16px', marginBottom: '40px', fontFamily: 'Source Han Sans SC' }}>{en}</p>
                <div style={{ width: '40px', height: '4px', backgroundColor: 'rgba(61,55,68,1)' }}></div>
            </div>
        )
    } else {
        return (
            <div style={{ flexDirection: 'column', display: 'flex', alignItems: 'center', marginBottom: '49px', marginTop: '49px' }} id={id}>
                <p style={{ color: '#3D3744', fontSize: '16px', fontFamily: 'Source Han Sans SC', fontWeight: 'bold', lineHeight: '30px', marginBottom: '12px' }}>{cn}</p>
                <p style={{ color: '#898989', fontWeight: 400, fontSize: '12px', marginBottom: '15px', fontFamily: 'Source Han Sans SC' }}>{en}</p>
                <div style={{ width: '13px', height: '4px', backgroundColor: 'rgba(61,55,68,1)' }}></div>
            </div>
        )
    }

}

export default Description;
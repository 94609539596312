import React, { useState } from 'react';
import { useMedia } from 'react-use';
import PcForm from './pc/pc_form';
import PhoneForm from './phone/phone_form';
import { useViewport } from '../../../../unitl/screen.js';
function Form() {
    const { width } = useViewport();
    const breakpoint = 640;
    if (width > breakpoint) {
        return (
            <PcForm />
        )
    } else {
        return (
            <PhoneForm />
        )
    }

}
export default Form;
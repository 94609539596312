import React, { useState } from 'react';
import { useMedia } from 'react-use';
import ReactDOM from 'react-dom';
import 'antd/dist/antd.css';
import './ant_form.css';
import { useViewport } from '../../../../unitl/screen.js';
import { Form, Input, InputNumber, Button, message } from 'antd';
const layout = {
    labelCol: {
        span: 6,
    },
    wrapperCol: {
        span: 16,
    },
};
const validateMessages = {
    required: '请输入${label}',
    types: {
        email: '不是有效的${label}',
        number: '不是有效的${label}',
    },
};
function SubmitForm(props) {
    const { width } = useViewport();
    const breakpoint = 620;
    const onFinish = values => {
        console.log(values);
        if(values){
            message.success('发送成功')
        }
    };
    // const isWide = useMedia('(min-width: 480px)');
    const iswide = width < breakpoint;
    console.log(props)
    return (
        <Form name="nest-messages" onFinish={onFinish} validateMessages={validateMessages} layout='vertical' labelAlign='right'
            style={{
                width: iswide ? '240px' : '600px',
                margin: 'auto', padding: 0
            }} labelCol={{ offset: iswide ? 10 : 0 }}
        >
            <Form.Item
                name={['user', 'name']}
                label="昵称"
                rules={[
                    {
                        required: true,
                    },
                ]}
                style={{
                    padding: 0,
                    marginTop: iswide ? '10px' : '20px',
                    marginBottom: iswide ? '10px' : '20px'
                }}
            >
                <Input style={{
                    height: iswide ? '30px' : '60px',
                    padding: 0,
                    borderRadius: '6px'
                }} />
            </Form.Item>
            <Form.Item
                style={{
                    marginTop: iswide ? '10px' : '20px',
                    marginBottom: iswide ? '10px' : '20px',
                    padding: 0
                }}
                name={['user', 'phone']}
                label="手机号"
                rules={[
                    {
                        required: true,
                    },
                    ({ getFieldValue }) => ({
                        validator(rule, value) {
                            if (!value) {
                                return Promise.resolve();
                            }

                            if (!(/^1[34578]\d{9}$/.test(value))) {
                                return Promise.reject('请输入正确的手机号');
                            }
                            return Promise.resolve();
                        },
                    }),
                ]}

            >
                <Input style={{
                    height: iswide ? '30px' : '60px',
                    padding: 0,
                    borderRadius: '6px'
                }} />
            </Form.Item>
            <Form.Item name={['user', 'introduction']} label="留言" style={{
                marginTop: iswide ? '10px' : '20px',
                marginBottom: iswide ? '10px' : '20px',
                padding: 0
            }}>
                <Input.TextArea style={{
                    height: iswide ? '120px' : '187px',
                    borderRadius: '6px'
                }} />
            </Form.Item>
            <Form.Item>
                <Button
                    type="primary"
                    htmlType="submit"
                    shape='round'
                    className='button-color-sunset'
                    style={{
                        width: iswide ? '96px' : '171px',
                        height: iswide ? '30px' : '53px',
                        fontSize: iswide ? '12px' : '24px'
                    }}>
                    发送
                 </Button>
            </Form.Item>
        </Form>
    )


}

export default SubmitForm;
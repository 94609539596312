import React, { useState } from 'react';
import './pc_score.css';
// import '../Common/index.css';
import Universal from '../../../../../img/pc/Universal.png';
import profession from '../../../../../img/pc/profession.png';
import PcIntrduction from '../../intrduction/Pc';
function PcScore() {
    return (
        <div style={{ display: 'flex', alignItems: 'center', overflow: 'hidden' }} >
            <div className='pc_scoreContent' >
                <div style={{ flexDirection: 'column', display: 'flex' }}>
                    <img src={Universal} style={{ width: '699px', height: '364px' }} />
                    <img src={profession} style={{ width: '80%', height: '364px' }} />
                </div>
                <PcIntrduction
                    title={'通用记分器&专业记分器'}
                    detail={['记录对手、搭档、场数、时间、得分、失误、统计、分 析、预测、胜负、级别、排行、语音...一切尽在掌握！', ' 针对不同场景、不同需求的人群定制的专业羽毛球记分器。', ' 通用记分器遵循简单易用的原则，让您随时随地开始比赛', ' 专业记分器严格遵守职业羽毛球规则，让您展现实力的同时尽显专业风范！']}
                    supplement={'纪录历史  分析趋势  重温往事'}
                    btntext={'开始比赛'}
                />
            </div>
            <div className='pc_Scorebody'>
                <div className='pc_scoreblackMask'>
                </div>
            </div>
        </div>

    );
}

export default PcScore;
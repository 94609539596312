import React, { useState } from 'react';
import './pc_Team.css';
import Teamage from '../../../../../img/pc/Teamage.png';
import pc_TeamTarget from '../../../../../img/pc/pc_TeamTarget.png';
import targeticon from '../../../../../img/pc/targeticon.png';
import statistics from '../../../../../img/pc/statistics.png';
import activeicon from '../../../../../img/pc/activeicon.png';
import rateicon from '../../../../../img/pc/rateicon.png';
import PcIntrduction from '../../intrduction/Pc';
function PcTeam() {
    return (
        <div style={{ display: 'flex', alignItems: 'center', overflow: 'hidden' }}>
            <div className='pc_TeamContent'>
                <div>
                    <PcIntrduction
                        title={'球队管理 —— 创建&加入球队'}
                        detail={['谁都能轻松的成为球队管理者。', '球队成员统筹管理、球队指标实时监控、球队活动一键创建、活动经费自动记录、图片视频记录美好时光、物料管理清晰透明。 手把手教你成为一名称职的羽毛球队长！']}
                        supplement={'省去纸张 一次完成 数据不丢'}
                        btntext={'创建比赛'}
                    />
                    <ul className='iconlist'>
                        <li><img src={targeticon} className='teamicon' /></li>
                        <li><img src={statistics} className='teamicon' /></li>
                        <li><img src={activeicon} className='teamicon' /></li>
                        <li><img src={rateicon} className='teamicon' /></li>
                    </ul>
                </div>

                <div style={{ flexDirection: 'column', display: 'flex', alignItems: 'flex-end' }}>
                    <img src={Teamage} style={{ width: '406px', height: '364px' }} />
                    <img src={pc_TeamTarget} style={{ width: '406px', height: '364px' }} />
                </div>
            </div>
            <div className='pc_Teambody'>
                <div className='pc_TeamblackMask'>
                </div>
            </div>
        </div>

    );
}
export default PcTeam;
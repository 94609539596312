import React, { useState } from 'react';
import { useMedia } from 'react-use';
import PcTeam from './pc/pc_Team';
import PhoneTeam from './phone/phone_Team';
import { useViewport } from '../../../../unitl/screen.js';
// import PhoneScore from './phone/Score/phone_score.js';
function Team() {
    const { width } = useViewport();
    const breakpoint = 620;
    const isWide = useMedia('(min-width: 480px)');
    if (width > breakpoint) {
        return (
            <PcTeam />
        )
    } else {
        return (
            <PhoneTeam />
        )
    }

}

export default Team;
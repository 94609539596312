import React, { useState, useEffect } from "react";
import wx from "weixin-js-sdk";
import "./bottom.css";
import downloadApp from "../../../../unitl/download";
import { Form, Input, InputNumber, Button, message } from "antd";
function Bottomphone() {
  const [isHide, setDownloadStatus] = useState(true);
  useEffect(() => {
    let ua = navigator.userAgent.toLowerCase();
    console.log(wx, ua, "111111111111");
    var isWeixin = ua.indexOf("micromessenger") != -1;
    // if (isWeixin) {
    //   //
    //   setDownloadStatus(false);
    // }
    wx.miniProgram.getEnv(function (res) {
      setDownloadStatus(false);
    });

    return () => {
      console.log("Component will unmount");
    };
  }, []);

  const downloadApp1 = () => {
    message.info("请在应用商店搜索《行动榜》下载");
  };
  if (isHide) {
    return <div />;
  } else {
    return <div className="bottom" onClick={() => downloadApp1()}></div>;
  }
}
export default Bottomphone;

import React, { useState } from "react";
import "./index";
import Head from "./Component/Header";
import Score from "./Component/Score";
import Description from "./Component/description/description";
import Team from "./Component/Team";
import Match from "./Component/Match";
import Personal from "./Component/Personal";
import Form from "./Component/form";
import Foot from "./Component/Foot";
import Bottom from "./Component/bottom";
function Home() {
  return (
    <div className="App">
      <Head />
      <Description
        cn={"神器·记分器 —— 记录系统"}
        en={"MAGICAL SCORER RECORDING SYSTEM"}
        id="Introduction"
      />
      <Score />
      <Description
        cn={"球队管理系统 —— 球队系统"}
        en={"PRACTICALISTURE TEAMMANAGMENTSYSTEM"}
      />
      <Team />
      <Description
        cn={"编排计算器 —— 赛事系统"}
        en={"PRACTICALISTURE TEAMMANAGMENTSYSTEM"}
      />
      <Match />
      <Description
        cn={"数据排行 —— 纪录系统"}
        en={"DATA RANKING RECORD SYSTEM"}
      />
      <Personal />
      <Description cn={"联系我们"} en={"LILAN SYSTEM"} id="link" />
      <Form />
      <Foot />
      <Bottom />
    </div>
  );
}

export default Home;

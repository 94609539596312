import React, { useState } from 'react';
import './pc_match.css';
// import '../Common/index.css';
import createMatch from '../../../../../img/pc/createMatch.png';
import matchDetail from '../../../../../img/pc/matchDetail.png';
import mtachRecord from '../../../../../img/pc/mtachRecord.png';
import PcIntrduction from '../../intrduction/Pc';
function PcMatch() {
    return (
        <div style={{ display: 'flex', alignItems: 'center', overflow: 'hidden' }}>
            <div className='pc_matchContent'>
                <div style={{ flexDirection: 'column', display: 'flex',justifyContent:'space-bewteen' }}>
                    <img src={createMatch} style={{ width: '383px', height: '230px' }} />
                    <img src={matchDetail} style={{ width: '383px', height: '230px' }} />
                    <img src={mtachRecord} style={{ width: '383px', height: '230px' }} />
                </div>
                <PcIntrduction
                    title={'赛事系统'}
                    detail={['小到个人、俱乐部，大到全国乃至全球赛事，轻松完成编排、发布、过程、统计等赛事全过程。', ' 让组织赛事不再是一个劳神伤财的过程，让组织者和参与者都享受到一站式服务。', ' 通用记分器遵循简单易用的原则，让您随时随地开始比赛', ' 赛事一键生成，选手一键报名，赛程自动生成，实时追踪永久保留']}
                    supplement={'一个人10分钟创建整场赛事。'}
                    btntext={'组织赛事'}
                />
            </div>
            <div className='pc_Matchbody'>
                <div className='pc_matchblackMask'>
                </div>
            </div>
        </div>

    );
}

export default PcMatch;
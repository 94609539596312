import React, { useState } from "react";
import { useMedia } from "react-use";

import Bottomphone from "./bottom_phone.js";
import { useViewport } from "../../../../unitl/screen.js";

function Bottom() {
  const { width } = useViewport();
  const breakpoint = 620;
  if (width < breakpoint) {
    return <Bottomphone />;
  } else {
    return <div />;
  }
}
export default Bottom;


import React, { useState } from 'react';
import { useMedia } from 'react-use';
import './index.css'
import downloadApp from '../../../../../unitl/download';
function PcIntrduction(props) {
    console.log(props)
    return (
        <div className='Introduction'>
            <p className='pc_intrduction_title'>{props.title}</p>
            <div style={{ marginTop: '56px', marginBottom: '44px' }}>
                {
                    props.detail && props.detail.map((ele, i) => {
                        return (
                            <p key={i} className='pc_intrduction_detail'>{ele}</p>
                        )
                    })
                }
            </div>
            <p className='pc_intrduction_supplement'>{props.supplement}</p>
            <a className='btn' onClick={() => downloadApp()}>
                <p style={{ color: '#fff', fontSize: '22px' }}>{props.btntext}</p>
            </a>
        </div>
    )
}

export default PcIntrduction;
import React, { useState } from 'react';
import './phone_Personal.css';
// import '../Common/index.css';
import phone_teamRank from '../../../../../img/phone/phone_teamRank.png';
import phone_persnaldata from '../../../../../img/phone/phone_persnaldata.png';
import phone_personalback from '../../../../../img/phone/phone_personalback.png';
import PhoneIntrduction from '../../intrduction/Phone';
function PhonePersonal() {
    return (
        <div className='phone_Personalbody'>
            <div className='Personal_content'>
                <img src={phone_teamRank} style={{ width: '65%', height: '200px' }} />
                <img src={phone_persnaldata} style={{ width: '65%', height: '200px' }} />
                <PhoneIntrduction
                    title={'赛事系统'}
                    detail={['你的运动数据、对阵历史、比赛纪录、好友球友统统一目了然。 让您的运动生涯有一个清晰的纪录，每一天的积累都是未来的成就与财富。', ' 全国排行、好友排行，让您对个人定位有清晰的认知，理性运动，有目标的提升自己']}
                    supplement={'纪录你的运动生涯'}
                    btntext={'组织赛事'}
                />
            </div>
            <div className='phone_PersonalblackMask'>
            </div>
        </div>
    );
}
export default PhonePersonal;
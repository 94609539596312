import React, { useState } from 'react';
import './phone_form.css';
import SubmitForm from '../ant_form';
// import '../Common/index.css';
function PhoneForm() {
    return (
        <div className='phone_Formbody'>
            <div className='Form_content'>
                <SubmitForm />
            </div>
            <div className='phone_FormblackMask'>
            </div>
        </div>
    );
}
export default PhoneForm;